<template>
	<div>
		<el-form :model="ruleForm" :inline="true">
			<el-form-item label="用户名">
				<el-input v-model="ruleForm.name" placeholder="用户名"></el-input>
			</el-form-item>
			<el-form-item label="手机号">
				<el-input v-model="ruleForm.phone" placeholder="手机号"></el-input>
			</el-form-item>
			<el-form-item>
				<div class="block">
					<span class="demonstration" style="margin-right: 10px">时间</span>
					<el-date-picker v-model="time" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"
						value-format="yyyy-MM-dd HH:mm:ss" @change="changeTime">
					</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item>
				<el-button @click="getInfo" type="primary">搜索</el-button>
			</el-form-item>
		</el-form>
		<el-table max-height="510px" :data="tableData" stripe style="width: 100%;min-height: 510px">
			<el-table-column type="expand">
				<template slot-scope="scope">
					<el-form label-position="left" inline class="table-expand">

						<el-form-item label="头像">
							<img v-if="!scope.row.face" src="../../../static/touxiang.png">
							<img style="width: 60px;height: 60px" v-if="scope.row.face" :src="scope.row.face" />
						</el-form-item>
						<el-form-item label="用户名称"><span>{{ scope.row.name }}</span></el-form-item>
						<el-form-item label="用户id"><span>{{ scope.row.id }}</span></el-form-item>
						<el-form-item label="位置信息"><span>{{ scope.row.address }}</span></el-form-item>
						<el-form-item label="手机号"><span>{{ scope.row.phone }}</span></el-form-item>
						<!-- <el-form-item label="充值总金额"><span>{{ scope.row.totalAmount }}</span></el-form-item> -->
						<el-form-item label="注册时间"><span>{{ scope.row.gmtCreate }}</span></el-form-item>
					</el-form>
				</template>
			</el-table-column>
			<el-table-column prop="name" label="用户名"></el-table-column>
			<el-table-column prop="phone" label="手机号"></el-table-column>
			<el-table-column prop="address" label="地址"></el-table-column>
			<!-- <el-table-column prop="totalAmount" label="充值金额（元）"></el-table-column> -->
			<el-table-column prop="gmtCreate" label="注册时间"></el-table-column>
			<!-- <el-table-column prop="dell" label="删除"></el-table-column> -->
			<el-table-column v-if="User == 'true'" fixed="right" label="操作" width="100">
				<template slot-scope="scope">
					<el-button @click="dell(scope.row.id)" type="text" size="small">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="block" style="display: flex;justify-content: flex-start;margin-top: 20px">

			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="1"
				:page-sizes="[10, 20, 50, 100]" :page-size="ruleForm.size"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		name: "UserManage",
		data() {
			return {
				User: '',
				ruleForm: {
					"beginTime": "",
					"endTime": "",
					"name": "",
					"page": 1,
					"phone": "",
					"size": 10,
					"dell": '删除'
				},
				total: 0,
				tableData: [],
				time: '',
				pickerOptions: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
			}
		},
		created() {
			this.User = sessionStorage.getItem("User")
			this.getInfo()
		},
		methods: {
			dell(id) {
				let _this = this
				console.log(id);
				// let dellid = {
				//  userId: id
				// }
				//  this.$ajax('dell', 'GET', id).then(res => {
				//    console.log(res,'删除')
				// // console.log(id);
				//  })
				axios.get('https://charge.cheersofa.com/user/delete/' + id).then(
					response => { //successcallback
						console.log(response.data) // 得到返回结果数据
						_this.getInfo()
						_this.$message({
							type: 'success',
							message: '删除成功'
						});
					}).catch(
					error => {
						console.log(error.message) //错误信息
					})
			},
			getInfo() {
				this.$ajax('userPage', 'post', this.ruleForm).then(res => {
					console.log(res)
					this.tableData = res.data.records
					this.total = res.data.total
				})
			},
			changeTime(time) {
				console.log(time)
				if (time) {
					this.ruleForm.beginTime = time[0]
					this.ruleForm.endTime = time[1]
				} else {
					this.ruleForm.beginTime = ''
					this.ruleForm.endTime = ''
				}
				this.getInfo()
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.ruleForm.size = val
				this.getInfo()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.ruleForm.page = val
				this.getInfo()
			}
		}
	}
</script>

<style scoped lang="scss">
	.table-expand {
		label {
			width: 180px;
			color: #99a9bf;
		}

	}

	.table-expand .el-form-item {
		margin-right: 0;
		margin-bottom: 0;
		width: 50%;
	}
</style>
